.red_colr {
  color: #dc302d;
}

.header_main {
  position: relative;
}

.header_main header {
  background-color: #000;
  /* border-top: 2px solid #fff; */
  border-bottom: 1px solid #6d2e27;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

header div[class*="MuiDrawer-modal-"]>div[class*="MuiDrawer-paper-"],
header div[class*="jss"]>div[class*="jss"] {
  overflow: visible;
}

aside.desktop_menus .menu_bar {
  fill: #fff;
}

.mobile_menus .close_icon {
  z-index: 99999;
  cursor: pointer;
  font-size: 28px;
  transition: all 0.5s ease;
}

.mobile_menus .close_icon:hover {
  transform: rotate(90deg);
}

.header_main header .menu_wrap {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  padding: 0;
}

.header_main header .logo_titleText {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  width: 90px;
  height: 72px;
}

.header_main header .logo_titleText img {
  margin-right: 15px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 8px;
}

.header_main header .logo_titleText .site_title_text h1 span {
  display: inline-block;
}

.header_main header .logo_titleText .site_title_text h1 {
  font-size: 29px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #131725;
  letter-spacing: 0.4px;
}

.header_main header .logo_titleText .site_title_text>p {
  font-size: 12px;
  text-align: center;
  color: #333333;
  font-family: "Josefin Sans", sans-serif !important;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.header_main header .header_toolbar {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1310px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.header_main header .menu_wrap a {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 24px 12px;
  position: relative;
  letter-spacing: 0.2px;
}

.header_main header .menu_wrap>li>a {
  color: #fff;
}

.header_main header .menu_wrap>li {
  padding: 0;
  margin: 0 30px 0 0px;
  overflow: visible;
  position: relative;
  height: 100%;
}

.header_main header .menu_wrap>li>a::after {
  content: "";
  width: 0%;
  height: 3px;
  background-color: #e05640;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease;
}

.header_main header .menu_wrap>li:hover>a::after {
  content: "";
  width: 100%;
}

.header_main header .menu_wrap>li.active>a::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #e05640;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.header_main header .menu_wrap li:hover,
.header_main header .menu_wrap li:focus,
.header_main header .header_toolbar .call_now_top li:hover,
.header_main header .header_toolbar .call_now_top li:focus {
  background-color: transparent;
}

.header_main header .menu_wrap>li.active>a,
.header_main header .menu_wrap>li>a:hover,
.header_main header .menu_wrap>li:hover>a {
  color: #e05640;
}

.header_main header .header_toolbar .call_now_top li {
  flex-direction: column;
  overflow: visible;
  line-height: normal;
  height: auto;
  padding: 0 15px;
}

.header_main header .header_toolbar .call_now_top li a {
  color: #fff;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.header_main header .header_toolbar .call_now_top li a span.material-icons {
  font-size: 18px;
  transform: translateY(4px);
  margin-right: 2px;
}

.header_main header .header_toolbar .call_now_top li p {
  font-size: 12px;
  color: #949494;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

.header_main header .header_toolbar .call_now_top {
  position: relative;
  /* padding: 20px 5px; */
}

.header_main header .header_toolbar .call_now_top::before {
  content: "";
  width: 2px;
  height: 35px;
  background-color: #7a7a7b;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header_main header .header_toolbar aside {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  align-items: center;
}

.header_main header .header_toolbar .call_now_top li a:hover {
  color: #e05640;
}

.header_main header .header_toolbar li a:hover {
  cursor: pointer;
}

.header_main header .header_toolbar li a:focus {
  outline: none;
}

.header_main header .menu_wrap>li>div {
  top: 100%;
  z-index: 999;
  left: 0px;
}

.header_main header .menu_wrap>li .sub_menu li {
  padding: 0;
  height: auto;
  margin-right: 0px;
}

.header_main header .menu_wrap>li .sub_menu li a {
  padding: 10px 15px;
  display: block !important;
  width: 100%;
}

.header_main header .menu_wrap>li .sub_menu li a:hover {
  color: #dc302d;
}

.header_main .mega_menu .sub_menu {
  min-width: auto !important;
  width: 100%;
  padding: 10px !important;
  box-sizing: border-box;
}

.header_main .mega_menu .sub_menu_categories {
  padding: 15px;
  width: 33.33%;
  box-sizing: border-box;
}

.header_main .mega_menu .sub_menu_categories_row {
  align-items: flex-start;
  white-space: normal;
  flex-wrap: wrap;
}

.header_main .mega_menu .sub_menu_categories li a {
  padding: 0 0 7px 0 !important;
  font-weight: 500;
  border: 0 !important;
}

.header_main .mega_menu .sub_menu_categories a.cat_head_title {
  color: #1b2139 !important;
  letter-spacing: 0.4px !important;
  font-weight: 600 !important;
}

.header_main .mega_menu .sub_menu_categories a.cat_head_title:hover {
  color: #e05640 !important;
}

.header_main header .menu_wrap>li>a .arrow_down {
  position: absolute;
  right: -10px;
}

.header_main header .menu_wrap .sub_menu {
  min-width: 200px;
  padding: 0;
}

img.menu_page_icon {
  max-width: 42px;
  min-width: 42px;
  vertical-align: middle;
  margin-right: 5px;
}

/* .contact_us_btn {
  padding: 12px 0 !important;
}

.contact_us_btn a::after {
  display: none;
}

.contact_us_btn a {
  padding: 10px 20px !important;
  background-color: #e05640;
  border: 1px solid transparent;
  transition: 0.5s;
}

.contact_us_btn a:hover {
  color: #fff !important;
  border-color: #fff;
  background-color: transparent;
}
.header_main header .menu_wrap .contact_us_btn a{
  background-color: #FFFFFF;
  color: #e05640;
}
.header_main header .menu_wrap .contact_us_btn a:hover {
  color: #fff;
  border-color: #e05640;
  background-color: #e05640;
} */
div#menu-list-grow {
  border-radius: 0;
}

.header_main header .menu_wrap>li.mega_menu>div {
  width: 1024px;
  left: -100px;
}

.header_main .mega_menu .sub_menu_categories li:not(:first-child) a {
  white-space: normal;
  font-size: 16px;
  display: flex !important;
  display: -ms-flexbox;
  align-items: center;
}

.header_main header .menu_wrap>li .sub_menu li.current_page_item a,
.header_main header .menu_wrap>li.current_page_item>a {
  color: #e05640 !important;
}

.header_main .mega_menu .sub_menu_categories img.menu_items_icons,
.header_main ul li a img {
  max-width: 25px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 5px;
}

.header_main header .menu_wrap>li .sub_menu .sub_menu_categories li a .text_wrap {
  float: right;
  width: calc(100% - 35px);
}

.header_main header .menu_wrap .sub_menu>li>a {
  font-weight: 500;
}

.header_main header .header_toolbar .call_now_top .second_number {
  margin-left: 40px;
}

.header_main header .header_toolbar .call_now_top .first_number .material-icons {
  position: relative;
  top: 14px;
  font-size: 24px;
  margin-right: 10px;
}

.header_main header .header_toolbar .call_now_top .first_number a:hover .material-icons {
  color: #fff !important;
}

.header_main header .menu_wrap .sub_menu.hireDevelopersMenu {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
}

.header_main header .menu_wrap .sub_menu.hireDevelopersMenu>li {
  width: calc(33.33% - 1px);
}

.header_main header .menu_wrap .sub_menu.hireDevelopersMenu>li:nth-child(odd) {
  border-right: 1px solid #ddd;
}

.header_main header [class|=MuiTouch] {
  display: none;
}

.header_main header li>span {
  display: none;
}

/* .header_main header .menu_wrap .contact_us_btn a::before {
  background: #fff;
}
.header_main header .menu_wrap .contact_us_btn a:hover {
  color: #fff !important;
} */
.header_main header .menu_wrap>li.mega_menu.hire_developers>div {
  left: -200px;
}

.header_main header .menu_wrap>li.mega_menu.hire_developers>div .sub_menu.hireDevelopersMenu {
  width: 100%;
  padding: 15px !important;
}

.header_main header .menu_wrap>li.mega_menu.hire_developers>div .sub_menu.hireDevelopersMenu li a,
.header_main header .menu_wrap>li.mega_menu.hire_developers>div .sub_menu.hireDevelopersMenu li {
  border: 0px;
}

.header_main header .menu_wrap>li.mega_menu.hire_developers img.menu_items_icons {
  max-width: 25px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 5px;
}

.header_main header .menu_wrap>li.company .sub_menu {
  padding: 5px 0px;
}

.header_main header .menu_wrap>li.portfolios #menu-list-grow {
  padding: 5px 0px;
}

.header_main .mega_menu_short .sub_menu {
  padding: 10px !important;
}

.header_main .mega_menu_short .sub_menu_categories li:not(:first-child) a {
  white-space: normal;
  font-size: 16px;
  display: flex !important;
  display: -ms-flexbox;
  align-items: center;
}

.header_main .mega_menu_short .sub_menu_categories a.cat_head_title {
  color: #1b2139 !important;
  letter-spacing: 0.4px !important;
  font-weight: 600 !important;
}

.header_main .mega_menu_short .sub_menu_categories li a {
  padding: 0 0 7px 0 !important;
  font-weight: 500;
  border: 0 !important;
}

/***Mobile Side Menu Style**/

.mobile_menus .menu_wrap li a {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}

.mobile_menus .menu_wrap li.active a,
/* .mobile_menus .menu_wrap li a:focus, */
.mobile_menus .call_now_top li a:hover,
.mobile_menus .call_now_top li a:focus {
  color: #dc302e;
  cursor: pointer;
}

.mobile_menus .menu_wrap li:hover,
.mobile_menus .menu_wrap li:focus {
  background-color: #00000000;
}

.mobile_menus li a:focus {
  outline: none;
}

.mobile_menus .call_now_top {
  border-top: 1px solid #e9e9e9;
}

.mobile_menus .call_now_top li {
  flex-direction: column;
  overflow: visible;
  line-height: normal;
  height: auto;
}

.mobile_menus .call_now_top li a {
  color: #444a60;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.mobile_menus .call_now_top li p {
  font-size: 12px;
  color: #949494;
  text-transform: uppercase;
}

/* .mobile_menus {
  max-height: 100vh;
  overflow: scroll;
} */
div[class*="MuiDrawer-modal-"].mobile_menu_wrap>div[class*="MuiDrawer-paper-"] {
  width: 100%;
}

.mobile_menus .home_and_close_icon .close_icon {
  position: absolute;
  right: 10px;
}

.mobile_menus img.menu_items_icons {
  max-width: 25px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
}

.mobile_menus .home_and_close_icon {
  width: 50px;
  height: 40px;
  padding: 0;
}

.mobile_menus .contactUs_mobile {
  margin-bottom: 15px;
}

.mobile_menus .contactUs_mobile a {
  padding: 8px 18px !important;
  background-color: #e05640;
  border: 1px solid transparent;
  transition: 0.5s;
  color: #fff !important;
  display: inline-block;
}

.mobile_menu_wrap>div {
  width: 100%;
}

.mobile_menus ul li {
  justify-content: space-between;
}

.mobile_menus ul li svg {
  padding: 10px;
  padding-right: 0;
}

.mobile_menus .mobile_logo_toggle {
  height: auto;
  padding-bottom: 0;
}

.mobile_menus .mobile-top-close-bar {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.mobile_menus ul.menu_wrap {
  padding-top: 0;
}

.mobile_menus .mobile_logo_toggle a img {
  width: 70px;
}

.mobile_menus .social_links {
  justify-content: center;
}

.mobile_menus .social_links a:hover {
  border-color: #ed402d;
}

.header_main .sub_menu .sub_menu_categories_row_port {
  align-items: flex-start;
  white-space: normal;
  flex-wrap: wrap;

}

.header_main .sub_menu .sub_menu_categories_row_port .sub_menu_categories {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
}

.header_main header .menu_wrap>li.mega_menu_short>div {
  width: 680px;
  left: -200px;
}

.sub_menu_categories_row_port>ul>li>a {
  padding: 0 0 7px 0 !important;
}


.first_number,
.second_number {
  display: flex;
  align-items: center;
  gap: 14px;
}

/* .first_number>a:last-child {
  margin-top: 6px;
}

.header_main header .header_toolbar .call_now_top li a {
  font-size: 15px;
} */


@media (min-width: 1280px) {
  .header_main header .header_toolbar .call_now_top li a {
    font-size: 16px;
  }
}

@media (max-width: 1264.98px) {

  /* .header_main header .header_toolbar aside {
      flex-direction: row-reverse;
  } */
  .header_main header .header_toolbar .call_now_top::before {
    opacity: 0;
  }
}

@media (max-width: 767.98px) {
  .header_main header .logo_titleText .site_title_text h1 {
    font-size: 18px;
  }

  .header_main header .logo_titleText .site_title_text>p {
    font-size: 9px;
  }

  .header_main header .header_toolbar .call_now_top li a {
    font-size: 12px;
  }

  .mobile_menus .mobile_logo_toggle a img {
    width: 50px;
  }
}

@media (max-width: 599.98px) {
  /* .header_main header .logo_titleText {
      width: 75px;
      height: 66px;
  } */
}

.innerMenuItems {
  margin-left: 15px !important;
}

.innerMenuItemsService {
  margin-left: 25px !important;
}

@media (min-width: 1280px) and (max-width: 1599.98px) {
  /* .header_main header .logo_titleText img {
    width: 70%;
  } */
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .header_main header .menu_wrap li {
    margin: 0 25px 0 0px;
  }

  .header_main header .menu_wrap>li.mega_menu>div {
    left: -180px;
    width: 900px;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  /* .header_main header .logo_titleText img {
    width: 65%;
  } */
}