body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Multilinks a {
  color: inherit;
}

.footer_main .footer_top ul li .Multilinks {
  font-size: 12px;
  color: #949494;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.7;
}

.footer_main .footer_top ul li .Multilinks>:is(a, span) {
  display: inline-block;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.2;
}